import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import Masthead from "../components/strapi/structure/Masthead"
import Twocol from "../components/strapi/structure/TwoCol"
import Craftsmanship from "../components/strapi/structure/Craftsmanship"
import Outropiggy from "../components/strapi/structure/Outropiggy"
import FlexjetRow from "../components/strapi/jetting/FlexjetRow"

export default function Jetting({ data }) {
  return (
    <Layout>
      <SEO title="Jetting" />

      {data.allStrapiJetting.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.jet_mh.mh_title}
            mhsubtitle={node.jet_mh.mh_subtitle}
            mhbackground={node.jet_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.jet_flex.txtimg_title}
            description={node.jet_flex.txtimg_description}
            blueTitle={node.jet_flex.txtimg_bluetitle}
            blueLink={node.jet_flex.txtimg_bluelink}
            bgColor={node.jet_flex.txtimg_bgcolor}
            order={node.jet_flex.txtimg_order}
            rowimg={node.jet_flex.txtimg_img.publicURL}
          />
          <Craftsmanship
            title={node.jet_craftsmanship.craft_title}
            description={node.jet_craftsmanship.craft_description}
          />
          <Twocol
            title={node.jet_system.txtimg_title}
            description={node.jet_system.txtimg_description}
            blueTitle={node.jet_system.txtimg_bluetitle}
            blueLink={node.jet_system.txtimg_bluelink}
            bgColor={node.jet_system.txtimg_bgcolor}
            order={node.jet_system.txtimg_order}
            rowimg={node.jet_system.txtimg_img.publicURL}
          />
          <FlexjetRow />
          <Outropiggy outro={node.flexjet_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query Jetting {
    allStrapiJetting {
      edges {
        node {
          id # id of the node
          jet_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          jet_flex {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          jet_craftsmanship {
            id
            craft_title
            craft_description
          }
          jet_system {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_img {
              publicURL
            }
          }
          flexjet_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`
